<template>
  <div>
    <!-- <v-card-title class="modal-cadastro-titulo">
       {{ item. }}
    </v-card-title> -->
    <!-- <div class="modal-extrato-credito-titulo-total"></div> -->

    <v-card-text class="modal-cadastro-form">
      <v-skeleton-loader
        :loading="loadingTable"
        :transition="'fade-transition'"
        height="350px"
        type="table">
          <v-data-table
            :headers="headers"
            :items="listExtratoPremio"
            flat
            dense
          >
            <template v-slot:item.value="{ item }">
              <span :style="`color: ${getColor(item.entryType ? item.entryType.type : null)};`">
                {{ item.value | currency }}
              </span>
            </template>

            <template slot="body.append">
              <tr>
                <th>Total</th>
                <th>{{ totalValue() | currency }}</th>
                <!-- <th>teste</th> -->
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </v-card-text>
    </div>
</template>

<script>
import variables from '@/assets/styles/helpers/_variables.scss'
import { mapGetters } from 'vuex'

export default {
  name: 'UsuariowebExtratoPremio',
  data: () => ({
    headers: [
      { align: 'start', class: 'table-header', text: 'Descrição', value: 'description' },
      { align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { align: 'start', class: 'table-header', text: 'Data', value: 'date' }
    ],
  }),
  computed: {
    ...mapGetters('usuariosWeb', {item: 'item', listExtratoPremio: 'listExtratoPremio', loadingTable: 'loadingTable'}),
    variables: () => variables,
  },

  methods: {
    getColor (type) {
      switch (type) {
        case 'D':
          return this.variables.colorError
        case 'C':
          return this.variables.colorSecondary
      }
    },
    totalValue () {
      let self = this
      let total = 0
      self.listExtratoPremio.forEach(element => {
        if (element.entryType) {
          if (element.entryType.type === 'C') {
            total += parseFloat(element.value)
          } else {
            total -= parseFloat(element.value)
          }
        }
      });
      return total
    }
  }
}
</script>
